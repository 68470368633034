
import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import { Navigation } from "../Navigation";
import { ObtLogo } from "../../components/ObtLogo";
import { MobileMenu } from "../MobileMenu";
import { getModeParm }  from '../../utils/getModeParm';
import { AppData } from "../AppContext";
import "./style.css";

export const ObtHeader = ({ breakpoint, className }) => {
  // const { currentLanguage } = AppData();
  // const languageId = currentLanguage['iso'];
  // const haveLanguage = languageId !== undefined && languageId !== null && languageId !== ""?true:false;
  const isOBTHelps = process.env.REACT_APP_NAME==="OBT Helps"?true:false;
  const appClassName = isOBTHelps ? "obthelps" : "akuo";
  const linkToPlayStore = process.env.REACT_APP_GOOGLE_PLAY_URL?process.env.REACT_APP_GOOGLE_PLAY_URL:null;
  const linkToAppleStore = process.env.REACT_APP_IOS_STORE_URL?process.env.REACT_APP_IOS_STORE_URL:null;
  const { isAppleDevice, isAndroidDevice } = AppData();

  const {isBeta} = getModeParm();  
  // console.log("isBeta", isBeta);

  const [mobileOpen, setMobileOpen] = useState(false);
  let menuRef = useRef();

  const showNavMenu = () => {
    // console.log("showNavMenu");
    setMobileOpen(!mobileOpen);
  }

  useEffect(() => {
    let handler = (e)=>{
      //console.log("useEffect e", e);
      if (menuRef.current !== undefined && menuRef.current !== null) {
        if(!menuRef.current.contains(e.target)){
          setMobileOpen(false);
          //console.log("menuRef", menuRef.current);
        }      
      }
    };

    document.addEventListener("mousedown", handler);
    return() =>{
      document.removeEventListener("mousedown", handler);
    }

  });


  return (
    <>
    <div
      className={`obt-header ${breakpoint} ${className} `}
    >
      {["desktop", "tablet"].includes(breakpoint) && (
        <>
          <div className="logo">
            <ObtLogo
              className={`obt-logo-instance ${appClassName}`}
              logoStyle={breakpoint}
              navHome={true}
            />  
          </div>

          <Navigation className="navigation-instance" breakpoint={breakpoint}/>
        
          <div className="right-nav-wrapper" >
            {!isAppleDevice && <img className={`appstore-button style-header-${breakpoint}`} style={{"maxHeight":"6rem"}} alt='Get it on Google Play'
                                src='/img/google-play-badge-english.png' onClick={() => {window.open(linkToPlayStore)}}/>
            }
            {!isAndroidDevice &&
                <img className={`appstore-button style-header-${breakpoint}`} style={{"maxHeight":"6rem"}} alt='Download on the App Store'
                              src='/img/apple-black-english.svg' onClick={() => {window.open(linkToAppleStore)}}/>
              }

            {isBeta && <p className="beta-text">BETA</p>}

            {/* <ObtNavigation className={`class-${breakpoint}`} state="logged-in" />  */}
          </div>
        </>
      )}

      
       
      
      {["mobile"].includes(breakpoint) && (
        <>
          <div className="obt-icon-wrap-wrapper">            
            <ObtLogo
              className={`obt-logo-instance ${appClassName}`}
              logoStyle={breakpoint}
              navHome={true}
            />
          </div>
          <div className="mobile-menu-wrapper" >
            <MobileMenu 
              className="mobile-menu-instance"
              breakpoint={breakpoint}
              onClick={showNavMenu}
            />
          </div>
          <div className="store-buttons-container-mobile">
            {!isAppleDevice && <div className="mobile-appstore-wrapper" >
            <img className={`appstore-button style-header-${breakpoint}`} style={{"maxHeight":"6rem"}} alt='Get it on Google Play'
                                src='/img/google-play-badge-english.png' onClick={() => {window.open(linkToPlayStore)}}/>
            </div>
            }
            {!isAndroidDevice && <div className="mobile-appstore-wrapper" >
            <img className={`appstore-button style-header-${breakpoint}`} style={{"maxHeight":"6rem"}} alt='Download on the App Store'
                                src='/img/apple-black-english.svg' onClick={() => {window.open(linkToAppleStore)}}/>
            </div>
            }
          </div>
        </>
      )}
    </div>

    {["mobile"].includes(breakpoint) && 
      <div className="mobile-nav-container" ref={menuRef}>
      <div className={`mobile-nav-menu ${mobileOpen? 'active' : 'inactive'}`} >
        <Navigation
          className="mobile-navigation-instance"
          breakpoint={breakpoint}
          setMobileOpen={setMobileOpen}
        />
      </div>
      </div>
    }

    </>
  );
};

ObtHeader.propTypes = {
  breakpoint: PropTypes.oneOf(["desktop", "tablet", "mobile"]),
};
